
// 定制化
export const encodeMappingDev = [

  {
    'en': 'smile',
    'char': '/::)',
    'cn': '微笑'
  },
  {
    'en': 'grimance',
    'char': '/::~',
    'cn': '撇嘴'
  },
  {
    'en': 'drool',
    'char': '/::B',
    'cn': '色'
  },
  {
    'en': 'scowl',
    'char': '/::|',
    'cn': '发呆'
  },
  // {
  //   'en': 'cool_guy',
  //   'char': '/:8-)',
  //   'cn': '得意'
  // },

  {
    'en': 'sob',
    'char': '/::<',
    'cn': '流泪'
  },
  {
    'en': 'shy',
    'char': '/::$',
    'cn': '害羞'
  },
  // {
  //   'en': 'silent',
  //   'char': '/::X',
  //   'cn': '闭嘴'
  // },
  // {
  //   'en': 'sleep',
  //   'char': '/::Z',
  //   'cn': '睡'
  // },
  {
    'en': 'cry',
    'char': '/::\'(',
    'cn': '大哭'
  },
  {
    'en': 'awkward',
    'char': '/::-|',
    'cn': '尴尬'
  },
  // {
  //   'en': 'angry',
  //   'char': '/::@',
  //   'cn': '发怒'
  // },
  {
    'en': 'tongue',
    'char': '/::P',
    'cn': '调皮'
  },
  {
    'en': 'grin',
    'char': '/::D',
    'cn': '呲牙'
  },
  // {
  //   'en': 'surprise',
  //   'char': '/::O',
  //   'cn': '惊讶'
  // },
  {
    'en': 'frown',
    'char': '/::(',
    'cn': '难过'
  },
  {
    'en': 'ruthless',
    'char': '/::+',
    'cn': '酷'
  },
  {
    'en': 'blush',
    'char': '/:--b',
    'cn': '囧'
  },
  {
    'en': 'scream',
    'char': '/::Q',
    'cn': '抓狂'
  },
  // {
  //   'en': 'puke',
  //   'char': '/::T',
  //   'cn': '吐'
  // },
  {
    'en': 'chuckle',
    'char': '/:,@P',
    'cn': '偷笑'
  },
  {
    'en': 'joyful',
    'char': '/:,@-D',
    'cn': '愉快'
  },
  {
    'en': 'happy',
    'char': '/:happy',
    'cn': '开心'
  },

  // {
  //   'en': 'slight',
  //   'char': '/::d',
  //   'cn': '白眼'
  // },
  {
    'en': 'smug',
    'char': '/:,@o',
    'cn': '傲慢'
  },
  // {
  //   'en': 'hungry',
  //   'char': '/::g',
  //   'cn': '饥饿'
  // },
  // {
  //   'en': 'drowsy',
  //   'char': '/:|-)',
  //   'cn': '困'
  // },
  // {
  //   'en': 'panic',
  //   'char': '/::!',
  //   'cn': '惊恐'
  // },
  {
    'en': 'sweat',
    'char': '/::L',
    'cn': '流汗'
  },
  {
    'en': 'laugh',
    'char': '/::>',
    'cn': '憨笑'
  },
  {
    'en': 'commando',
    'char': '/::,@',
    'cn': '悠闲'
  },
  {
    'en': 'determined',
    'char': '/:,@f',
    'cn': '奋斗'
  },
  // {
  //   'en': 'scold',
  //   'char': '/::-S',
  //   'cn': '咒骂'
  // },
  {
    'en': 'shocked',
    'char': '/:?',
    'cn': '疑问'
  },
  {
    'en': 'shhh',
    'char': '/:,@x',
    'cn': '嘘'
  },
  {
    'en': 'dizzy',
    'char': '/:,@@',
    'cn': '晕'
  },
  {
    'en': 'tormented',
    'char': '/::8',
    'cn': '疯了'
  },
  // {
  //   'en': 'toasted',
  //   'char': '/:,@!',
  //   'cn': '衰'
  // },
  // {
  //   'en': 'skull',
  //   'char': '/:!!!',
  //   'cn': '骷髅'
  // },
  // {
  //   'en': 'hammer',
  //   'char': '/:xx',
  //   'cn': '敲打'
  // },
  {
    'en': 'wave',
    'char': '/:bye',
    'cn': '再见'
  },
  // {
  //   'en': 'speechless',
  //   'char': '/:wipe',
  //   'cn': '擦汗'
  // },
  // {
  //   'en': 'nose_pick',
  //   'char': '/:dig',
  //   'cn': '抠鼻'
  // },
  // {
  //   'en': 'clap',
  //   'char': '/:handclap',
  //   'cn': '鼓掌'
  // },
  {
    'en': 'shame',
    'char': '/:&-(',
    'cn': '糗大了'
  },
  {
    'en': 'fight',
    'char': '/:@)',
    'cn': '抱拳'
  },
  {
    'en': 'kiss',
    'char': '/::*',
    'cn': '亲亲'
  },
  // {
  //   'en': 'sly',
  //   'char': '/:X-)',
  //   'cn': '阴险'
  // },
  {
    'en': 'laughFlip',
    'char': '/:laughFlip',
    'cn': '笑翻'
  },
  {
    'en': 'whimper',
    'char': '/:8*',
    'cn': '可怜'
  },
  {
    'en': 'relieved',
    'char': '/:relieved', //
    'cn': '放松'
  },

  // {
  //   'en': 'trick',
  //   'char': '/:B-)',
  //   'cn': '坏笑'
  // },
  {
    'en': 'sun',
    'char': '/:sun',
    'cn': '太阳'
  },
  {
    'en': 'cat',
    'char': '/:cat)',
    'cn': '猫咪'
  },
  {
    'en': 'rose',
    'char': '/:rose',
    'cn': '玫瑰'
  },

  {
    'en': 'heart',
    'char': '/:heart',
    'cn': '爱心'
  },
  // {
  //   'en': 'dog',
  //   'char': '/:dog)',
  //   'cn': '狗狗'
  // },
  {
    'en': 'clap',
    'char': '/:handclap',
    'cn': '鼓掌'
  },
  {
    'en': 'shake',
    'char': '/:share',
    'cn': '握手'
  },

  {
    'en': 'party',
    'char': '/:party)',
    'cn': '庆祝'
  },
  {
    'en': 'thumbs_up',
    'char': '/:strong',
    'cn': '强'
  },

  // {
  //   'en': 'pig',
  //   'char': '/:pig',
  //   'cn': '猪头'
  // },

  {
    'en': 'popping',
    'char': '/:popping)',
    'cn': '香槟'
  },
  {
    'en': 'watermelon',
    'char': '/:<W>',
    'cn': '西瓜'
  },

  {
    'en': 'gift',
    'char': '/:gift',
    'cn': '礼物'
  },

  {
    'en': 'doughnut',
    'char': '/:doughnut', // /:doughnut
    'cn': '甜甜圈'
  }
  // {
  //   'en': 'cola',
  //   'char': '/:cola', // /:doughnut
  //   'cn': '可乐'
  // }

]
