'use strict'

Object.defineProperty(exports, '__esModule', { value: true })

const zhCn = {
  name: 'zh-cn',
  btn: {
    ok: '好的',
    confirm: '确认',
    yes: '是的',
    no: '不',
    toagent: '转人工',
    exitAgent: '退出人工',
    accept: '同意',
    noThinks: '不，谢谢',
    submit: '提交',
    picture: '图片',
    file: '文件',
    cancel: '取消',
    connecting: '连接中',
    login: 'login'
  },
  temp: {
    dialog: {
      emptyMsgTips: '请输入内容后再发送!',
      send: '发送',
      sendTips: '支持按Enter键发送'
    },
    header: {
      login: '登录',
      logout: '退出登录',
      logoutLoadingText: '退出登录中...',
      themeChange: '主题切换',
      bgChange: '背景切换'
    },
    slider: {
      clickPause: '点击暂停播放',
      clickPlay: '点击自动播放',
      clickLook: '点击看看哦',
      oneClickSkinning: '一键换肤'
    },
    answer: {
      // AnswerCommonFaq.vue
      yes: '是的',
      no: '不',
      confirmClear: '确定要清空所有收藏么？',
      confirmCancel: '确定要取消收藏么？',
      clear: '清空',
      cancel: '取消收藏',
      change: '换一换',
      // AnswerMultiIntention.vue
      more: '更多',
      // AnswerMultiSteps.vue
      resolve: '已解决',
      // AnswerRichtext.vue
      fullscreen: '全屏',
      important: '重要提示'
    },
    common: {
      relatedQuestion: '相关问题',
      fileSizeLimit: '图片超出了限制',
      clickStore: '点击收藏',
      clickCancelStore: '点击取消收藏',
      storeQuestion: '收藏问题',
      prevStep: '上一步',
      nextStep: '下一步',
      note: '注意',
      buy: 'Buy now',
      copy: '复制',
      copySuccess: '复制成功',
      from: 'From',
      off: 'OFF',
      parseDialog: '是否发送图片',
      parseDialogFile: '是否发送文件',
      dialogTextarea: '您可以同时编辑文字发送...'
    }
  },
  el: {
    colorpicker: {
      confirm: '确定',
      clear: '清空'
    },
    datepicker: {
      now: '此刻',
      today: '今天',
      cancel: '取消',
      clear: '清空',
      confirm: '确定',
      selectDate: '选择日期',
      selectTime: '选择时间',
      startDate: '开始日期',
      startTime: '开始时间',
      endDate: '结束日期',
      endTime: '结束时间',
      prevYear: '前一年',
      nextYear: '后一年',
      prevMonth: '上个月',
      nextMonth: '下个月',
      year: '年',
      month1: '1 月',
      month2: '2 月',
      month3: '3 月',
      month4: '4 月',
      month5: '5 月',
      month6: '6 月',
      month7: '7 月',
      month8: '8 月',
      month9: '9 月',
      month10: '10 月',
      month11: '11 月',
      month12: '12 月',
      week: 'week',
      weeks: {
        sun: '日',
        mon: '一',
        tue: '二',
        wed: '三',
        thu: '四',
        fri: '五',
        sat: '六'
      },
      months: {
        jan: '一月',
        feb: '二月',
        mar: '三月',
        apr: '四月',
        may: '五月',
        jun: '六月',
        jul: '七月',
        aug: '八月',
        sep: '九月',
        oct: '十月',
        nov: '十一月',
        dec: '十二月'
      }
    },
    select: {
      loading: '加载中',
      noMatch: '无匹配数据',
      noData: '无数据',
      placeholder: '请选择'
    },
    cascader: {
      noMatch: '无匹配数据',
      loading: '加载中',
      placeholder: '请选择',
      noData: '暂无数据'
    },
    pagination: {
      goto: '前往',
      pagesize: '条/页',
      total: '共 {total} 条',
      pageClassifier: '页',
      deprecationWarning: '你使用了一些已被废弃的用法，请参考 el-pagination 的官方文档'
    },
    messagebox: {
      title: '提示',
      confirm: '确定',
      cancel: '取消',
      error: '输入的数据不合法!'
    },
    upload: {
      deleteTip: '按 delete 键可删除',
      delete: '删除',
      preview: '查看图片',
      continue: '继续上传'
    },
    table: {
      emptyText: '暂无数据',
      confirmFilter: '筛选',
      resetFilter: '重置',
      clearFilter: '全部',
      sumText: '合计'
    },
    tree: {
      emptyText: '暂无数据'
    },
    transfer: {
      noMatch: '无匹配数据',
      noData: '无数据',
      titles: ['列表 1', '列表 2'],
      filterPlaceholder: '请输入搜索内容',
      noCheckedFormat: '共 {total} 项',
      hasCheckedFormat: '已选 {checked}/{total} 项'
    },
    image: {
      error: '加载失败'
    },
    pageHeader: {
      title: '返回'
    },
    popconfirm: {
      confirmButtonText: '确定',
      cancelButtonText: '取消'
    }
  },
  AnswerWarrantyMoli: {
    warranty: 'In warranty',
    extended: 'Extended warranty',
    expiredIn: 'Expired in',
    expired: 'Expired'
  },
  AnswerProductInfo: {
    Learn: 'Learn more'
  },
  FromCallBack: {
    Call: 'Treść prośby o oddzwonienie',
    Time: 'Czat pozostały do końca sesji:',
    Sorry: 'Przepraszamy, proszę wypełnić puste pola',
    Name: 'Nazwać',
    NameErrorTips: 'Proszę zostaw swoje imię',
    Country: 'Kraj',
    Please: 'Please select the country you bought your product',
    Email: 'Email',
    EmailErrorTips: 'Please leave your email address',
    emailMessage: 'Jeśli miałeś wcześniej z nami kontakt, sugerujemy użycie tego samego adresu email.',
    PhoneNumber: 'Numer telefonu',
    PhoneNumberValidTips: 'Proszę zostawić poprawny numer telefonu',
    AvailableTime: 'Dostępny termin',
    AvailableTimeRequireTips: 'Proszę wybrać odpowiedni czas odpowiedzi.Pamiętaj, że otrzymasz połączenie z numeru "brak identyfikatora dzwoniącego"',
    Describe: 'Proszę opisać swój problem',
    Procudt: 'Produkt',
    IMEI: 'IMEI',
    submit: 'Składać',
    note: '¿Nos has contactado anteriormente?',
    require: 'jest wymagane'
  },
  Prompt: {
    thank: '谢谢您的反馈!',
    surveyTimeText: '评价已失效期待再次为您服务',
    connecting: '正在链接...',
    Succeded: 'Reservation Succeded',
    Failed: 'Reservation Failed',
    timeOut: 'Sorry, your application has timed out. If you need to resubmit, please return to M.I.A to reapply. Sorry for the inconsistency.',
    startOver: 'Start over',
    close: '关闭',
    Retry: '重试',
    THANKS: '',
    ratevirtual: '给机器人评价'
  }
}

exports['default'] = zhCn
// # sourceMappingURL=zh-cn.js.map
