'use strict'

Object.defineProperty(exports, '__esModule', { value: true })

const de = {
  name: 'de',
  btn: {
    ok: 'OK',
    confirm: 'Confirmar',
    yes: 'Ja',
    no: 'Nein',
    toagent: 'Mit einem Agenten Live Chatten',
    exitAgent: 'Exit Agent',
    accept: 'Ich akzeptiere',
    noThinks: 'No Thanks',
    submit: 'Senden',
    picture: 'picture',
    file: 'file',
    cancel: 'Abbrechen',
    connecting: 'Verbinden',
    login: 'Anmelden'
  },
  temp: {
    dialog: {
      emptyMsgTips: 'Bitte geben Sie vor dem Absenden den Inhalt ein!',
      send: 'senden',
      sendTips: 'Unterstützt nur die Eingabetaste zum Senden'
    },
    header: {
      login: 'Einloggen',
      logout: 'Austragen',
      logoutLoadingText: 'Austragen...',
      themeChange: 'Thema wechseln',
      bgChange: 'Hintergrund ändern'
    },
    slider: {
      clickPause: 'Klicken Sie hier, um die Wiedergabe anzuhalten',
      clickPlay: 'Klicken Sie hier, um automatisch abzuspielen',
      clickLook: 'Zum Anzeigen klicken',
      oneClickSkinning: 'Häuten'
    },
    answer: {
      // AnswerCommonFaq.vue
      yes: 'Ja',
      no: 'Nein',
      confirmClear: 'Möchten Sie wirklich alle Favoriten löschen?',
      confirmCancel: 'Möchten Sie die Favoriten wirklich aufheben?',
      clear: 'leer',
      cancel: 'unbeliebt',
      change: 'Nächste Seite',
      // AnswerMultiIntention.vue
      more: 'More',
      // AnswerMultiSteps.vue
      resolve: 'Problem gelöst',
      // AnswerRichtext.vue
      fullscreen: 'Ganzer Bildschirm',
      important: 'Wichtig'
    },
    common: {
      relatedQuestion: 'Ähnliche Fragen',
      fileSizeLimit: 'Bild überschreitet Limit',
      clickStore: 'Klicken Sie auf Favorit',
      clickCancelStore: 'Klicken Sie, um die Favoriten aufzuheben',
      storeQuestion: 'Lieblingsfrage',
      prevStep: 'Vorheriger Schritt',
      nextStep: 'Nächster Schritt',
      note: 'Notiz',
      buy: 'Kaufen Sie jetzt',
      copy: 'COPY',
      copySuccess: 'copy success',
      from: 'from',
      off: 'OFF',
      parseDialog: 'Möchten Sie Bilder senden',
      parseDialogFile: 'Möchten Sie die Datei senden',
      dialogTextarea: 'Sie können den Text bearbeiten und gleichzeitig senden'
    }
  },
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Leeren'
    },
    datepicker: {
      now: 'Jetzt',
      today: 'Heute',
      cancel: 'Abbrechen',
      clear: 'Leeren',
      confirm: 'OK',
      selectDate: 'Datum w\xE4hlen',
      selectTime: 'Uhrzeit w\xE4hlen',
      startDate: 'Startdatum',
      startTime: 'Startzeit',
      endDate: 'Enddatum',
      endTime: 'Endzeit',
      prevYear: 'Letztes Jahr',
      nextYear: 'N\xE4chtes Jahr',
      prevMonth: 'Letzter Monat',
      nextMonth: 'N\xE4chster Monat',
      day: 'Tag',
      week: 'Woche',
      month: 'Monat',
      year: '',
      month1: 'Januar',
      month2: 'Februar',
      month3: 'M\xE4rz',
      month4: 'April',
      month5: 'Mai',
      month6: 'Juni',
      month7: 'Juli',
      month8: 'August',
      month9: 'September',
      month10: 'Oktober',
      month11: 'November',
      month12: 'Dezember',
      weeks: {
        sun: 'So',
        mon: 'Mo',
        tue: 'Di',
        wed: 'Mi',
        thu: 'Do',
        fri: 'Fr',
        sat: 'Sa'
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'M\xE4r',
        apr: 'Apr',
        may: 'Mai',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Okt',
        nov: 'Nov',
        dec: 'Dez'
      }
    },
    select: {
      loading: 'L\xE4dt.',
      noMatch: 'Nichts gefunden.',
      noData: 'Keine Daten',
      placeholder: 'Daten w\xE4hlen'
    },
    cascader: {
      noMatch: 'Nichts gefunden.',
      loading: 'L\xE4dt.',
      placeholder: 'Daten w\xE4hlen',
      noData: 'Keine Daten'
    },
    pagination: {
      goto: 'Gehe zu',
      pagesize: ' pro Seite',
      total: 'Gesamt {total}',
      pageClassifier: ''
    },
    messagebox: {
      confirm: 'OK',
      cancel: 'Abbrechen',
      error: 'Fehler'
    },
    upload: {
      deleteTip: 'Klicke l\xF6schen zum entfernen',
      delete: 'L\xF6schen',
      preview: 'Vorschau',
      continue: 'Fortsetzen'
    },
    table: {
      emptyText: 'Keine Daten',
      confirmFilter: 'Anwenden',
      resetFilter: 'Zur\xFCcksetzen',
      clearFilter: 'Alles ',
      sumText: 'Summe'
    },
    tree: {
      emptyText: 'Keine Eintr\xE4ge'
    },
    transfer: {
      noMatch: 'Nichts gefunden.',
      noData: 'Keine Eintr\xE4ge',
      titles: ['Liste 1', 'Liste 2'],
      filterPlaceholder: 'Eintr\xE4ge filtern',
      noCheckedFormat: '{total} Eintr\xE4ge',
      hasCheckedFormat: '{checked}/{total} ausgew\xE4hlt'
    },
    image: {
      error: 'FEHLGESCHLAGEN'
    },
    pageHeader: {
      title: 'Zur\xFCck'
    },
    popconfirm: {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }
  },
  AnswerWarrantyMoli: {
    warranty: 'Innerhalb der Garantie',
    extended: 'Erweiterte Garantie',
    expiredIn: 'Abgelaufen in',
    expired: 'Abgelaufen'
  },
  AnswerProductInfo: {
    Learn: 'Mehr Informationen'
  },
  FromCallBack: {
    Call: 'Rückrufnachricht',
    Time: 'Countdown für die Auszeit:',
    Sorry: 'Entschuldigung, bitte füllen Sie die leeren Felder aus',
    Name: 'Name',
    NameErrorTips: 'Bitte hinterlassen Sie Ihren Namen',
    Country: 'Land',
    Please: 'Bitte wählen Sie das Land, in dem Sie Ihr Produkt gekauft haben',
    Email: 'Email',
    EmailErrorTips: 'Bitte hinterlassen Sie Ihre E-Mail-Adresse',
    emailMessage: 'Wenn Sie vorher Kontakt mit einem Agenten hatten, empfehlen wir Ihnen, Ihre E-Mail-Adresse zu hinterlassen.',
    PhoneNumber: 'Telefonnummer',
    PhoneNumberValidTips: 'Bitte hinterlassen Sie Ihre korrekte Telefonnummer',
    AvailableTime: 'Verfügbare Zeit',
    AvailableTimeRequireTips: 'Bitte wählen Sie Ihre passende Antwortzeit aus. Bitte beachten Sie, dass Sie einen Anruf von einer „keine Anrufer-ID“-Nummer erhalten werden',
    Describe: 'Bitte beschreiben Sie Ihr Problem',
    Procudt: 'Produkt',
    IMEI: 'IMEI',
    submit: 'Einreichen',
    note: '¿Nos has contactado anteriormente?',
    require: 'Wird benötigt'
  },
  Prompt: {
    thank: 'Vielen Dank für Ihr Feedback.',
    surveyTimeText: 'Die Evaluierung ist abgelaufen und wir freuen uns darauf, Ihnen zu helfen.',
    connecting: 'Verbindung wird hergestellt',
    Succeded: 'Reservierung erfolgreich',
    Failed: 'Reservierung fehlgeschlagen',
    timeOut: 'Tut mir leid, Ihre Anmeldung ist zeitlich begrenzt. Wenn Sie sich erneut anmelden wollen, kehren Sie bitte zum M.I.A. zurück. Entschuldigen Sie bitte die Unannehmlichkeiten.',
    startOver: 'Neuanfang',
    close: 'Schließen',
    Retry: 'Erneut versuchen',
    THANKS: 'Nein, Danke',
    ratevirtual: 'Virtuellen Agenten bewerten'
  }
}

exports['default'] = de
