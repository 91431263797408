'use strict'

Object.defineProperty(exports, '__esModule', { value: true })

const fr = {
  name: 'fr',
  btn: {
    ok: 'D\'accord',
    confirm: 'Confirmer',
    yes: 'Oui',
    no: 'Non',
    toagent: 'Discuter avec l\'agent',
    exitAgent: 'Agent de sortie',
    accept: 'Accepter',
    noThinks: 'Non merci',
    submit: 'Soumettre',
    picture: 'picture',
    file: 'file',
    cancel: 'Annulation',
    connecting: 'Connexion',
    login: 'connexion'
  },
  temp: {
    dialog: {
      emptyMsgTips: 'Veuillez entrer le contenu avant d\'envoyer!',
      send: 'Envoyer',
      sendTips: 'Appuyez sur la touche Entrée pour envoyer'
    },
    header: {
      login: 'Connexion',
      logout: 'se déconnecter',
      logoutLoadingText: 'se déconnecter...',
      themeChange: 'Changer de thème',
      bgChange: 'Changement de fond'
    },
    slider: {
      clickPause: 'Cliquez pour mettre la lecture en pause',
      clickPlay: 'Cliquez pour jouer automatiquement',
      clickLook: 'Cliquez pour voir',
      oneClickSkinning: 'Dépeçage'
    },
    answer: {
      // AnswerCommonFaq.vue
      yes: 'Oui',
      no: 'Non',
      confirmClear: 'Voulez-vous vraiment effacer tous les favoris ?',
      confirmCancel: 'Voulez-vous vraiment supprimer vos favoris ?',
      clear: 'vide',
      cancel: 'défavorisé',
      change: 'Page suivante',
      // AnswerMultiIntention.vue
      more: 'Suite',
      // AnswerMultiSteps.vue
      resolve: 'Problème résolu',
      // AnswerRichtext.vue
      fullscreen: 'Plein écran',
      important: 'Important'
    },
    common: {
      relatedQuestion: 'questions connexes',
      fileSizeLimit: 'L\'image dépasse la limite',
      clickStore: 'Cliquez sur Favoris',
      clickCancelStore: 'Cliquez pour supprimer les favoris',
      storeQuestion: 'Question préférée',
      prevStep: 'Étape précédente',
      nextStep: 'L\'étape suivante',
      note: 'Noter',
      buy: 'Acheter maintenant',
      copy: 'COPY',
      copySuccess: 'copy success',
      from: 'From',
      off: 'OFF',
      parseDialog: 'Envoyer des images ou non',
      parseDialogFile: 'Envoyer des fichiers ou non',
      dialogTextarea: 'Vous pouvez modifier le texte en même temps envoyer...'
    }
  },
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Effacer'
    },
    datepicker: {
      now: 'Maintenant',
      today: 'Auj.',
      cancel: 'Annuler',
      clear: 'Effacer',
      confirm: 'OK',
      selectDate: 'Choisir date',
      selectTime: 'Choisir horaire',
      startDate: 'Date d\xE9but',
      startTime: 'Horaire d\xE9but',
      endDate: 'Date fin',
      endTime: 'Horaire fin',
      prevYear: 'Ann\xE9e pr\xE9c\xE9dente',
      nextYear: 'Ann\xE9e suivante',
      prevMonth: 'Mois pr\xE9c\xE9dent',
      nextMonth: 'Mois suivant',
      year: '',
      month1: 'Janvier',
      month2: 'F\xE9vrier',
      month3: 'Mars',
      month4: 'Avril',
      month5: 'Mai',
      month6: 'Juin',
      month7: 'Juillet',
      month8: 'Ao\xFBt',
      month9: 'Septembre',
      month10: 'Octobre',
      month11: 'Novembre',
      month12: 'D\xE9cembre',
      weeks: {
        sun: 'Dim',
        mon: 'Lun',
        tue: 'Mar',
        wed: 'Mer',
        thu: 'Jeu',
        fri: 'Ven',
        sat: 'Sam'
      },
      months: {
        jan: 'Jan',
        feb: 'F\xE9v',
        mar: 'Mar',
        apr: 'Avr',
        may: 'Mai',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Ao\xFB',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'D\xE9c'
      }
    },
    select: {
      loading: 'Chargement',
      noMatch: 'Aucune correspondance',
      noData: 'Aucune donn\xE9e',
      placeholder: 'Choisir'
    },
    cascader: {
      noMatch: 'Aucune correspondance',
      loading: 'Chargement',
      placeholder: 'Choisir',
      noData: 'Aucune donn\xE9e'
    },
    pagination: {
      goto: 'Aller \xE0',
      pagesize: '/page',
      total: 'Total {total}',
      pageClassifier: '',
      deprecationWarning: ''
    },
    messagebox: {
      title: '',
      confirm: 'Confirmer',
      cancel: 'Annuler',
      error: 'Erreur'
    },
    upload: {
      deleteTip: 'Cliquer sur supprimer pour retirer le fichier',
      delete: 'Supprimer',
      preview: 'Aper\xE7u',
      continue: 'Continuer'
    },
    table: {
      emptyText: 'Aucune donn\xE9e',
      confirmFilter: 'Confirmer',
      resetFilter: 'R\xE9initialiser',
      clearFilter: 'Tous',
      sumText: 'Somme'
    },
    tree: {
      emptyText: 'Aucune donn\xE9e'
    },
    transfer: {
      noMatch: 'Aucune correspondance',
      noData: 'Aucune donn\xE9e',
      titles: ['Liste 1', 'Liste 2'],
      filterPlaceholder: 'Entrer un mot clef',
      noCheckedFormat: '{total} elements',
      hasCheckedFormat: '{checked}/{total} coch\xE9(s)'
    },
    image: {
      error: 'ECHEC'
    },
    pageHeader: {
      title: 'Retour'
    },
    popconfirm: {
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }
  },
  AnswerWarrantyMoli: {
    warranty: 'Sous garantie',
    extended: 'Garantie prolongée',
    expiredIn: 'Expiré dans',
    expired: 'Expiré'
  },
  AnswerProductInfo: {
    Learn: 'En apprendre plus'
  },
  FromCallBack: {
    Call: 'Message de rappel',
    Time: 'Compte à rebours deconnexion:',
    Sorry: 'Désolé, veuillez remplir les champs vides.',
    Name: 'Nom',
    NameErrorTips: 'Per favore, lascia il tuo nome',
    Country: 'Pays',
    Please: 'Veuillez sélectionner le pays dans lequel vous avez acheté votre produit',
    Email: 'E-mail',
    EmailErrorTips: 'Please leave your email address',
    emailMessage: 'Si vous avez déjà échangé avec des agents, nous vous suggérons de laisser cette adresse e-mail.',
    PhoneNumber: 'Phone Number',
    PhoneNumberValidTips: '11 chiffres requis',
    AvailableTime: 'Available time',
    AvailableTimeRequireTips: 'Please select your appropriate answering time. Please note you will recieve a phone call from "no caller ID" number',
    Describe: 'Merci de décrire votre problème',
    Procudt: 'Modèle produit',
    IMEI: 'IMEI',
    submit: 'Soumettre',
    note: '¿Nos has contactado anteriormente?',
    require: 'est requis'
  },
  Prompt: {
    thank: 'Merci pour votre avis.',
    surveyTimeText: 'Evaluation has expired and look forward to serving you again.',
    connecting: 'Connexion',
    Succeded: 'Réservation réussie',
    Failed: 'Réservation échouée',
    timeOut: 'Désolé, votre demande de support a expirée. Si vous devez la rouvrir,  veuillez retourner à M.I.A pour une nouvelle demande. Veuillez nous excuser pour la gêne occasionnée.',
    startOver: 'Recommencer',
    close: 'Fermer',
    Retry: 'Réessayer',
    THANKS: 'NO THANKS',
    ratevirtual: 'Évaluer l\'agent virtuel'
  }
}

exports['default'] = fr
// # sourceMappingURL=fr.js.map
