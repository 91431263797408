'use strict'

Object.defineProperty(exports, '__esModule', { value: true })

const it = {
  name: 'it',
  btn: {
    ok: 'Ok',
    confirm: 'Confermare',
    yes: 'Sì',
    no: 'No',
    toagent: 'Chatta con l\'agente',
    exitAgent: 'Esci dall\'agente',
    accept: 'Accettare',
    noThinks: 'No grazie',
    submit: 'Invia',
    picture: 'picture',
    file: 'file',
    cancel: 'annulla',
    connecting: 'connessione',
    login: 'accesso'
  },
  temp: {
    dialog: {
      emptyMsgTips: 'Si prega di inserire il contenuto prima di inviare!',
      send: 'Inviare',
      sendTips: 'Premere il tasto Invio per inviare'
    },
    header: {
      login: 'Accedere',
      logout: 'disconnettersi',
      logoutLoadingText: 'disconnettersi...',
      themeChange: 'Cambia tema',
      bgChange: 'Cambia sfondo'
    },
    slider: {
      clickPause: 'Fare clic per mettere in pausa la riproduzione',
      clickPlay: 'Fare clic per riprodurre automaticamente',
      clickLook: 'Clicca per vedere',
      oneClickSkinning: 'Scuoiatura'
    },
    answer: {
      // AnswerCommonFaq.vue
      yes: 'Sì',
      no: 'No',
      confirmClear: 'Sei sicuro di voler cancellare tutti i preferiti?',
      confirmCancel: 'Sei sicuro di voler eliminare i preferiti?',
      clear: 'chiaro',
      cancel: 'sfavorito',
      change: 'Pagina successiva',
      // AnswerMultiIntention.vue
      more: 'Di più',
      // AnswerMultiSteps.vue
      resolve: 'Problema risolto',
      // AnswerRichtext.vue
      fullscreen: 'A schermo intero',
      important: 'Importante'
    },
    common: {
      relatedQuestion: 'domande correlate',
      fileSizeLimit: 'L\'immagine supera il limite',
      clickStore: 'Fare clic su Preferito',
      clickCancelStore: 'Fare clic per eliminare i preferiti',
      storeQuestion: 'Domanda preferita',
      prevStep: 'Passo precedente',
      nextStep: 'Passo successivo',
      note: 'Nota',
      buy: 'Compra ora',
      copy: 'COPY',
      copySuccess: 'copy success',
      from: 'From',
      off: 'OFF',
      parseDialog: 'Vuoi inviare foto',
      parseDialogFile: 'Vuoi inviare il file',
      dialogTextarea: 'È possibile modificare il testo e inviarlo allo stesso tempo'
    }
  },
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Pulisci'
    },
    datepicker: {
      now: 'Ora',
      today: 'Oggi',
      cancel: 'Cancella',
      clear: 'Pulisci',
      confirm: 'OK',
      selectDate: 'Seleziona data',
      selectTime: 'Seleziona ora',
      startDate: 'Data inizio',
      startTime: 'Ora inizio',
      endDate: 'Data fine',
      endTime: 'Ora fine',
      prevYear: 'Anno precedente',
      nextYear: 'Anno successivo',
      prevMonth: 'Mese precedente',
      nextMonth: 'Mese successivo',
      year: '',
      month1: 'Gennaio',
      month2: 'Febbraio',
      month3: 'Marzo',
      month4: 'Aprile',
      month5: 'Maggio',
      month6: 'Giugno',
      month7: 'Luglio',
      month8: 'Agosto',
      month9: 'Settembre',
      month10: 'Ottobre',
      month11: 'Novembre',
      month12: 'Dicembre',
      weeks: {
        sun: 'Dom',
        mon: 'Lun',
        tue: 'Mar',
        wed: 'Mer',
        thu: 'Gio',
        fri: 'Ven',
        sat: 'Sab'
      },
      months: {
        jan: 'Gen',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'Mag',
        jun: 'Giu',
        jul: 'Lug',
        aug: 'Ago',
        sep: 'Set',
        oct: 'Ott',
        nov: 'Nov',
        dec: 'Dic'
      }
    },
    select: {
      loading: 'Caricamento',
      noMatch: 'Nessuna corrispondenza',
      noData: 'Nessun dato',
      placeholder: 'Seleziona'
    },
    cascader: {
      noMatch: 'Nessuna corrispondenza',
      loading: 'Caricamento',
      placeholder: 'Seleziona',
      noData: 'Nessun dato'
    },
    pagination: {
      goto: 'Vai a',
      pagesize: '/page',
      total: 'Totale {total}',
      pageClassifier: '',
      deprecationWarning: ''
    },
    messagebox: {
      title: '',
      confirm: 'OK',
      cancel: 'Cancella',
      error: 'Input non valido'
    },
    upload: {
      deleteTip: 'Premi cancella per rimuovere',
      delete: 'Cancella',
      preview: 'Anteprima',
      continue: 'Continua'
    },
    table: {
      emptyText: 'Nessun dato',
      confirmFilter: 'Conferma',
      resetFilter: 'Reset',
      clearFilter: 'Tutti',
      sumText: 'Somma'
    },
    tree: {
      emptyText: 'Nessun dato'
    },
    transfer: {
      noMatch: 'Nessuna corrispondenza',
      noData: 'Nessun dato',
      titles: ['Lista 1', 'Lista 2'],
      filterPlaceholder: 'Inserisci filtro',
      noCheckedFormat: '{total} elementi',
      hasCheckedFormat: '{checked}/{total} selezionati'
    },
    image: {
      error: 'FAILED'
    },
    pageHeader: {
      title: 'Back'
    },
    popconfirm: {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }
  },
  AnswerWarrantyMoli: {
    warranty: 'In garanzia',
    extended: 'Garanzia Estesa',
    expiredIn: 'Scaduta nel',
    expired: 'Scaduto/a'
  },
  AnswerProductInfo: {
    Learn: 'Scopri di più'
  },
  FromCallBack: {
    Call: 'richiesta di chiamata',
    Time: 'Conto alla rovescia prima che la sessione finisca',
    Sorry: 'Siamo spiacenti, si prega di compilare i campi vuoti',
    Name: 'Nome',
    NameErrorTips: 'Per favore, lascia il tuo nome',
    Country: 'Nazione',
    Please: 'Per favore, selezioni la nazione dove ha acquistato il Suo prodotto.',
    Email: 'E-mail',
    EmailErrorTips: 'Per favore lascia il tuo indirizzo emailBitte hinterlassen Sie Ihre E-Mail-Adresse',
    emailMessage: 'Se hai già contattato i nostri agenti in passato, per favore inserisci l\'indirizzo email fornito in precedenza',
    PhoneNumber: 'Numero di telefono',
    PhoneNumberValidTips: 'Si prega di lasciare il numero di telefono corretto',
    AvailableTime: 'Tempo disponibile',
    AvailableTimeRequireTips: 'Seleziona l\'orario di risposta appropriato. Tieni presente che riceverai una telefonata dal numero "nessun ID chiamante"',
    Describe: 'per piacere descrivi il problema riscontrato',
    Procudt: 'Prodotto',
    IMEI: 'IMEI',
    submit: 'Invia',
    note: '¿Nos has contactado anteriormente?',
    require: 'è obbligatorio'
  },
  Prompt: {
    thank: 'Grazie per la sua opinione.',
    surveyTimeText: 'La valutazione è scaduta e non vedo l\'ora di servirvi di nuovo.',
    connecting: 'Connettendo',
    Succeded: 'Prenotazione effettuata con successo',
    Failed: 'Richiesta fallita',
    timeOut: 'Ci scusiamo, la Sua sessione è terminata, per favore ritorni su M.I.A per riprovare. Ci scusiamo per il disagio.',
    startOver: 'Riniziare',
    close: 'Chiudi',
    Retry: 'Riprova',
    THANKS: 'NO GRAZIE',
    ratevirtual: 'Valuta agente virtuale'
  }
}

exports['default'] = it
// # sourceMappingURL=it.js.map
