'use strict'

Object.defineProperty(exports, '__esModule', { value: true })

const English = {
  name: 'en',
  btn: {
    ok: 'Ok',
    confirm: 'Confirm',
    yes: 'Yes',
    no: 'No',
    toagent: 'Chat With Agent',
    exitAgent: 'Exit Agent',
    accept: 'Accept',
    noThinks: 'No Thanks',
    submit: 'Submit',
    picture: 'picture',
    file: 'file',
    cancel: 'cancel',
    connecting: 'connecting',
    login: 'login'
  },
  temp: {
    dialog: {
      emptyMsgTips: 'Please enter the content before sending!',
      send: 'Send',
      sendTips: 'Press enter key to send'
    },
    header: {
      login: 'login',
      logout: 'logout',
      logoutLoadingText: 'logout...',
      themeChange: 'Switch Theme',
      bgChange: 'Change Background'
    },
    slider: {
      clickPause: 'Click to pause playback',
      clickPlay: 'Click to play automatically',
      clickLook: 'Click to see',
      oneClickSkinning: 'Skinning'
    },
    answer: {
      // AnswerCommonFaq.vue
      yes: 'Yes',
      no: 'No',
      confirmClear: 'Are you sure you want to clear all favorites?',
      confirmCancel: 'Are you sure you want to unfavorite?',
      clear: 'empty',
      cancel: 'unfavorite',
      change: 'Next Page',
      // AnswerMultiIntention.vue
      more: 'More',
      // AnswerMultiSteps.vue
      resolve: 'Issue Resolved',
      // AnswerRichtext.vue
      fullscreen: 'Full Screen',
      important: 'Important'
    },
    common: {
      relatedQuestion: 'Related Questions',
      fileSizeLimit: 'Image exceeds limit',
      clickStore: 'Click to Favorite',
      clickCancelStore: 'Click to unfavorite',
      storeQuestion: 'Favorite question',
      prevStep: 'Previous Step',
      nextStep: 'Next Step',
      note: 'Note',
      buy: 'Buy now',
      copy: 'COPY',
      copySuccess: 'Copy Success',
      from: 'From',
      off: 'OFF',
      parseDialog: 'Do you want to send pictures',
      parseDialogFile: 'Do you want to send files',
      dialogTextarea: 'You can edit the text and send it at the same time'
    }
  },
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Clear'
    },
    datepicker: {
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      selectDate: 'Select date',
      selectTime: 'Select time',
      startDate: 'Start Date',
      startTime: 'Start Time',
      endDate: 'End Date',
      endTime: 'End Time',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      year: '',
      month1: 'January',
      month2: 'February',
      month3: 'March',
      month4: 'April',
      month5: 'May',
      month6: 'June',
      month7: 'July',
      month8: 'August',
      month9: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'December',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat'
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
      }
    },
    select: {
      loading: 'Loading',
      noMatch: 'No matching data',
      noData: 'No data',
      placeholder: 'Select'
    },
    cascader: {
      noMatch: 'No matching data',
      loading: 'Loading',
      placeholder: 'Select',
      noData: 'No data'
    },
    pagination: {
      goto: 'Go to',
      pagesize: '/page',
      total: 'Total {total}',
      pageClassifier: '',
      deprecationWarning: 'Deprecated usages detected, please refer to the el-pagination documentation for more details'
    },
    messagebox: {
      title: 'Message',
      confirm: 'OK',
      cancel: 'Cancel',
      error: 'Illegal input'
    },
    upload: {
      deleteTip: 'press delete to remove',
      delete: 'Delete',
      preview: 'Preview',
      continue: 'Continue'
    },
    table: {
      emptyText: 'No Data',
      confirmFilter: 'Confirm',
      resetFilter: 'Reset',
      clearFilter: 'All',
      sumText: 'Sum'
    },
    tree: {
      emptyText: 'No Data'
    },
    transfer: {
      noMatch: 'No matching data',
      noData: 'No data',
      titles: ['List 1', 'List 2'],
      filterPlaceholder: 'Enter keyword',
      noCheckedFormat: '{total} items',
      hasCheckedFormat: '{checked}/{total} checked'
    },
    image: {
      error: 'FAILED'
    },
    pageHeader: {
      title: 'Back'
    },
    popconfirm: {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }
  },
  AnswerWarrantyMoli: {
    warranty: 'In warranty',
    extended: 'Extended warranty',
    expiredIn: 'Expired in',
    expired: 'Expired'
  },
  AnswerProductInfo: {
    Learn: 'Learn more'
  },
  FromCallBack: {
    Call: 'Call back message',
    Time: 'Time out countdown:',
    Sorry: 'Sorry, please fill in the empty fields',
    Name: 'Name',
    NameErrorTips: 'Please leave your name',
    Country: 'Country',
    Please: 'Please select the country you bought your product',
    Email: 'Email',
    EmailErrorTips: 'Please leave your email address',
    PhoneNumber: 'Phone Number',
    PhoneNumberValidTips: 'Please leave your correct phone number',
    AvailableTime: 'Available time',
    AvailableTimeRequireTips: 'Please select your appropriate answering time. Please note you will recieve a phone call from "no caller ID" number',
    Describe: 'Please describe your problem',
    Procudt: 'Product Model',
    IMEI: 'IMEI',
    submit: 'Submit',
    emailMessage: 'If you have contacted our agents before, we suggest you to leave that email address.',
    note: 'You contacted us before?',
    require: 'is required'
  },
  Prompt: {
    thank: 'Thank you for your feedback.',
    surveyTimeText: 'Evaluation has expired and look forward to serving you again.',
    connecting: 'Connecting',
    Succeded: 'Reservation Succeeded',
    Failed: 'Reservation Failed',
    timeOut: 'Sorry, your application has timed out. If you need to resubmit, please return to M.I.A to reapply. Sorry for the inconsistency.',
    startOver: 'Start over',
    close: 'Close',
    Retry: 'Retry',
    THANKS: 'NO THANKS',
    ratevirtual: 'Rate virtual agent'
  }
}

exports['default'] = English
// # sourceMappingURL=en.js.map
