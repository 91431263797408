import httpRequest from '@/request/index'
import { callBackPostMessageParams } from '@/apis/types'

// 提交Message
export function updatePlaceOrderStatus (param:callBackPostMessageParams) {
  return httpRequest({
    url: '/api/v1/callback/updatePlaceOrderStatus',
    method: 'post',
    data: param
  })
}
