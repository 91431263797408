import {updatePlaceOrderStatus} from '@/apis/callBack/callBack'

export const receiveMessage = () => {
  window.addEventListener('message', receiveMessages, false)
}
function receiveMessages (res:any) {
  if (res.data.feature === 'eTicketForm') {
    updatePlaceOrderStatus(res.data)
  }
}
