'use strict'

Object.defineProperty(exports, '__esModule', { value: true })

const pt = {
  name: 'pt',
  btn: {
    ok: 'OK',
    confirm: 'Confirm',
    yes: 'sim',
    no: 'Não',
    toagent: 'Bate-papo com agente',
    exitAgent: 'Sair do agente',
    accept: 'Aceitar',
    noThinks: 'Não, obrigado',
    submit: 'Enviar',
    picture: 'picture',
    file: 'file',
    cancel: 'cancelar',
    connecting: 'ligação',
    login: 'Entrar'
  },
  temp: {
    dialog: {
      emptyMsgTips: 'Insira o conteúdo antes de enviar!',
      send: 'Mandar',
      sendTips: 'Pressione a tecla enter para enviar'
    },
    header: {
      login: 'Conecte-se',
      logout: 'sair',
      logoutLoadingText: 'sair...',
      themeChange: 'Alternar tema',
      bgChange: 'Mude o fundo'
    },
    slider: {
      clickPause: 'Clique para pausar a reprodução',
      clickPlay: 'Clique para jogar automaticamente',
      clickLook: 'Clique para ver',
      oneClickSkinning: 'Esfola'
    },
    answer: {
      // AnswerCommonFaq.vue
      yes: 'sim',
      no: 'Não',
      confirmClear: 'Tem certeza de que deseja limpar todos os favoritos?',
      confirmCancel: 'Tem certeza de que deseja desfavoritar?',
      clear: 'vazio',
      cancel: 'não favorito',
      change: 'Próxima página',
      // AnswerMultiIntention.vue
      more: 'Mais',
      // AnswerMultiSteps.vue
      resolve: 'Problema resolvido',
      // AnswerRichtext.vue
      fullscreen: 'Tela cheia',
      important: 'Important'
    },
    common: {
      relatedQuestion: 'Perguntas relacionadas',
      fileSizeLimit: 'A imagem excede o limite',
      clickStore: 'Clique para Favorito',
      clickCancelStore: 'Clique para não favorito',
      storeQuestion: 'Pergunta favorita',
      prevStep: 'Passo anterior',
      nextStep: 'Próxima Etapa',
      note: 'Observação',
      buy: 'Compre mais',
      copy: 'COPIAR CÓDIGO',
      copySuccess: 'Copiado com Sucesso',
      from: 'From',
      off: 'OFF',
      parseDialog: 'Deseja enviar fotografias',
      parseDialogFile: 'Deseja enviar o ficheiro',
      dialogTextarea: 'Você pode editar o texto e enviá-lo ao mesmo tempo'
    }
  },
  el: {
    colorpicker: {
      confirm: 'Confirmar',
      clear: 'Limpar'
    },
    datepicker: {
      now: 'Agora',
      today: 'Hoje',
      cancel: 'Cancelar',
      clear: 'Limpar',
      confirm: 'Confirmar',
      selectDate: 'Selecione a data',
      selectTime: 'Selecione a hora',
      startDate: 'Data de inicio',
      startTime: 'Hora de inicio',
      endDate: 'Data de fim',
      endTime: 'Hora de fim',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      year: '',
      month1: 'Janeiro',
      month2: 'Fevereiro',
      month3: 'Mar\xE7o',
      month4: 'Abril',
      month5: 'Maio',
      month6: 'Junho',
      month7: 'Julho',
      month8: 'Agosto',
      month9: 'Setembro',
      month10: 'Outubro',
      month11: 'Novembro',
      month12: 'Dezembro',
      weeks: {
        sun: 'Dom',
        mon: 'Seg',
        tue: 'Ter',
        wed: 'Qua',
        thu: 'Qui',
        fri: 'Sex',
        sat: 'Sab'
      },
      months: {
        jan: 'Jan',
        feb: 'Fev',
        mar: 'Mar',
        apr: 'Abr',
        may: 'Mai',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Ago',
        sep: 'Set',
        oct: 'Out',
        nov: 'Nov',
        dec: 'Dez'
      }
    },
    select: {
      loading: 'A carregar',
      noMatch: 'Sem correspond\xEAncia',
      noData: 'Sem dados',
      placeholder: 'Selecione'
    },
    cascader: {
      noMatch: 'Sem correspond\xEAncia',
      loading: 'A carregar',
      placeholder: 'Selecione',
      noData: 'Sem dados'
    },
    pagination: {
      goto: 'Ir para',
      pagesize: '/pagina',
      total: 'Total {total}',
      pageClassifier: '',
      deprecationWarning: ''
    },
    messagebox: {
      title: 'Mensagem',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      error: 'Erro!'
    },
    upload: {
      deleteTip: 'press delete to remove',
      delete: 'Apagar',
      preview: 'Previsualizar',
      continue: 'Continuar'
    },
    table: {
      emptyText: 'Sem dados',
      confirmFilter: 'Confirmar',
      resetFilter: 'Limpar',
      clearFilter: 'Todos',
      sumText: 'Sum'
    },
    tree: {
      emptyText: 'Sem dados'
    },
    transfer: {
      noMatch: 'Sem correspond\xEAncia',
      noData: 'Sem dados',
      titles: ['List 1', 'List 2'],
      filterPlaceholder: 'Enter keyword',
      noCheckedFormat: '{total} items',
      hasCheckedFormat: '{checked}/{total} checked'
    },
    image: {
      error: 'FAILED'
    },
    pageHeader: {
      title: 'Back'
    },
    popconfirm: {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }
  },
  AnswerWarrantyMoli: {
    warranty: 'Dentro garantia',
    extended: 'Garantia extendida',
    expiredIn: 'Expirado em',
    expired: 'Expirado'
  },
  AnswerProductInfo: {
    Learn: 'Saiba mais'
  },
  FromCallBack: {
    Call: 'Treść prośby o oddzwonienie',
    Time: 'Czat pozostały do końca sesji:',
    Sorry: 'Przepraszamy, proszę wypełnić puste pola',
    Name: 'Nazwać',
    NameErrorTips: 'Proszę zostaw swoje imię',
    Country: 'Kraj',
    Please: 'Please select the country you bought your product',
    Email: 'Email',
    EmailErrorTips: 'Please leave your email address',
    emailMessage: 'Jeśli miałeś wcześniej z nami kontakt, sugerujemy użycie tego samego adresu email.',
    PhoneNumber: 'Numer telefonu',
    PhoneNumberValidTips: 'Proszę zostawić poprawny numer telefonu',
    AvailableTime: 'Dostępny termin',
    AvailableTimeRequireTips: 'Proszę wybrać odpowiedni czas odpowiedzi.Pamiętaj, że otrzymasz połączenie z numeru "brak identyfikatora dzwoniącego"',
    Describe: 'Proszę opisać swój problem',
    Procudt: 'Produkt',
    IMEI: 'IMEI',
    submit: 'Składać',
    note: '¿Nos has contactado anteriormente?',
    require: 'jest wymagane'
  },
  Prompt: {
    thank: 'Obrigado pelo seu feedback.',
    surveyTimeText: 'A avaliação expirou e estamos ansiosos para atendê-lo novamente',
    connecting: 'Conectando',
    Succeded: 'Reserva bem-sucedida',
    Failed: 'Falha na reserva',
    timeOut: 'Sorry, your application has timed out. If you need to resubmit, please return to M.I.A to reapply. Sorry for the inconsistency.',
    startOver: 'Recomeçar',
    close: 'Fechar',
    Retry: 'Repetir',
    THANKS: '',
    ratevirtual: 'Avaliar agente virtual'
  },
  message: {
    PromptPreSO: {
      title: 'Enviar para reparo',
      question1: 'Detalhes do dispositivo',
      imeiTips: 'Por favor, confirme as informações do seu dispositivo.',
      imeiInputTips: 'Informe o numero de IMEI do seu aparelho. (Em seu telephone digite <b style="color: #4e4e4e;">*#06#</b> para obter o numero composto por 15 digitos?)',
      imeiErrorTips: 'Desculpe, esse formato não parece correto.',
      imeiQueryRepairStatusTips: 'O dispositivo já tem um pedido de reparo não concluído.',
      warrantyTips: 'Por favor, confirme as informações do seu dispositivo.',
      question1LoadingText: 'Confirmando as informações do seu dispositivo. Isto pode tomar algum tempo. Por favor, espere.',
      question2: 'Informações de reparo',
      question2Tips: 'Forneça suas informações de reparo.',
      firstName: 'Nome',
      lastName: 'Sobrenome',
      contactNumber1: 'Telefone',
      contactNumber2: 'Telefone2',
      email: 'Email',
      postCode: 'Código postal',
      provinceOrState: 'Estado',
      city: 'Cidade',
      address: 'Endereço',
      subAddress1: 'Bairro',
      subAddress2: 'Delegacion o municipio',
      references: 'Referencias',
      whatsAppNotification: 'Você deseja receber notificação de alterações de status de reparo via WhatsApp?',
      whatsAppNumberTips: 'Número Whatsapp',
      rntNumber: 'Si se ha puesto en contacto con nuestro centro de atención telefónica, introduzca a continuación su número de referencia de 12 dígitos, por ejemplo, 000000-000000.',
      serviceNotes: 'Qual o problema do seu aparelho?',
      question3: 'Opciones de reparación',
      question3Tips: 'Elija su opción de reparación.',
      carrMailTitle: '¿Quieres recibir notificaciones de cambios de estado de reparación a través de WhatsApp?',
      carryIn: 'Visita el centro de servicio',
      mailIn: 'Envia por servicio de paquetería',
      questionAddressTitle: 'Dirección',
      question4: '4. Revisar dados',
      question4Tips: 'Favor revisar os dados antes do envio.',
      confirm: 'Confirme',
      next: 'Próxima',
      back: 'Voltar',
      findServiceCenter: 'Encontre centros de serviço',
      edit: 'Editar',
      submit: 'Enviar',
      emptyTips: 'Informação mandatória',
      radioTips: 'Selecione pelo menos um.'
    },
    PromptPreSOBR: {
      question2: 'Localize as assistências',
      question2Tips: 'Informe seu CEP ou sua geolocalização para encontrarmos assistências técnicas próximas de você.',
      zipcode: 'CEP',
      question2MapTips: 'Selecione a assistência desejada (até 3), a opção de agendamento e clique em “Próxima”',
      schedulingOptions: 'Favor escolher à opção de agendamento',
      option1: 'Atendimento reparo em até 48 horas',
      option2: 'Atendimento reparo rápido'
    }
  }
}

exports['default'] = pt
// # sourceMappingURL=pt.js.map
