'use strict'

Object.defineProperty(exports, '__esModule', { value: true })

const pl = {
  name: 'pl',
  btn: {
    ok: 'OK',
    confirm: 'potwierdź',
    yes: 'Tak',
    no: 'nie',
    toagent: 'Przenieść do ręcznika',
    exitAgent: 'Praca wyjściowa',
    accept: 'Akceptuj',
    noThinks: 'Nie, dziękuję.',
    submit: 'Zatwierdź',
    picture: 'zdjęcie',
    file: 'plik',
    cancel: 'anuluj',
    connecting: 'łączenie',
    login: 'login'
  },
  temp: {
    dialog: {
      emptyMsgTips: 'Proszę wprowadzić treść przed wysłaniem!',
      send: 'wyślij',
      sendTips: 'Wyślij klawiszem enter'
    },
    header: {
      login: '登录',
      logout: '退出登录',
      logoutLoadingText: '退出登录中...',
      themeChange: '主题切换',
      bgChange: '背景切换'
    },
    slider: {
      clickPause: 'Kliknij, aby wstrzymać odtwarzanie',
      clickPlay: 'Kliknij, aby grać automatycznie',
      clickLook: 'Kliknij, aby zobaczyć',
      oneClickSkinning: 'Jednym kliknięciem zmiana skóry'
    },
    answer: {
      // AnswerCommonFaq.vue
      yes: 'tak',
      no: 'nie',
      confirmClear: 'Na pewno chcesz opróżnić wszystkie swoje ulubione?',
      confirmCancel: 'Na pewno chcesz anulować kolekcję?',
      clear: 'pusty',
      cancel: 'Anuluj kolekcję',
      change: 'Zmiana',
      // AnswerMultiIntention.vue
      more: 'więcej',
      // AnswerMultiSteps.vue
      resolve: 'Sprawa rozwiązana',
      // AnswerRichtext.vue
      fullscreen: 'Pełny ekran',
      important: 'Ważne'
    },
    common: {
      relatedQuestion: 'Powiązane tematy',
      fileSizeLimit: 'Obraz przekracza limit',
      clickStore: 'Kliknij Ulubione',
      clickCancelStore: 'Kliknij, aby anulować kolekcję',
      storeQuestion: 'Kwestie gromadzenia',
      prevStep: 'Poprzedni krok',
      nextStep: 'następny krok',
      buy: 'Klaster',
      copy: 'KOPIA',
      copySuccess: 'powodzenie kopiowania',
      from: 'Od',
      off: 'Wyłączone',
      parseDialog: 'Chcesz wysłać zdjęcia',
      parseDialogFile: 'Chcesz wysłać plik',
      dialogTextarea: 'Możesz edytować tekst i wysłać go w tym samym czasie'
    }
  },
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Wyczy\u015B\u0107'
    },
    datepicker: {
      now: 'Teraz',
      today: 'Dzisiaj',
      cancel: 'Anuluj',
      clear: 'Wyczy\u015B\u0107',
      confirm: 'OK',
      selectDate: 'Wybierz dat\u0119',
      selectTime: 'Wybierz godzin\u0119',
      startDate: 'Data pocz\u0105tkowa',
      startTime: 'Godzina pocz\u0105tkowa',
      endDate: 'Data ko\u0144cowa',
      endTime: 'Czas ko\u0144cowa',
      prevYear: 'Poprzedni rok',
      nextYear: 'Nast\u0119pny rok',
      prevMonth: 'Poprzedni miesi\u0105c',
      nextMonth: 'Nast\u0119pny miesi\u0105c',
      year: 'rok',
      month1: 'stycze\u0144',
      month2: 'luty',
      month3: 'marzec',
      month4: 'kwiecie\u0144',
      month5: 'maj',
      month6: 'czerwiec',
      month7: 'lipiec',
      month8: 'sierpie\u0144',
      month9: 'wrzesie\u0144',
      month10: 'pa\u017Adziernik',
      month11: 'listopad',
      month12: 'grudzie\u0144',
      week: 'tydzie\u0144',
      weeks: {
        sun: 'niedz.',
        mon: 'pon.',
        tue: 'wt.',
        wed: '\u015Br.',
        thu: 'czw.',
        fri: 'pt.',
        sat: 'sob.'
      },
      months: {
        jan: 'STY',
        feb: 'LUT',
        mar: 'MAR',
        apr: 'KWI',
        may: 'MAJ',
        jun: 'CZE',
        jul: 'LIP',
        aug: 'SIE',
        sep: 'WRZ',
        oct: 'PA\u0179',
        nov: 'LIS',
        dec: 'GRU'
      }
    },
    select: {
      loading: '\u0141adowanie',
      noMatch: 'Brak dopasowa\u0144',
      noData: 'Brak danych',
      placeholder: 'Wybierz'
    },
    cascader: {
      noMatch: 'Brak dopasowa\u0144',
      loading: '\u0141adowanie',
      placeholder: 'Wybierz',
      noData: 'Brak danych'
    },
    pagination: {
      goto: 'Id\u017A do',
      pagesize: '/stron\u0119',
      total: 'Wszystkich {total}',
      pageClassifier: ''
    },
    messagebox: {
      title: 'Wiadomo\u015B\u0107',
      confirm: 'OK',
      cancel: 'Anuluj',
      error: 'Wiadomo\u015B\u0107 zawiera niedozwolone znaki'
    },
    upload: {
      deleteTip: 'kliknij kasuj aby usun\u0105\u0107',
      delete: 'Kasuj',
      preview: 'Podgl\u0105d',
      continue: 'Kontynuuj'
    },
    table: {
      emptyText: 'Brak danych',
      confirmFilter: 'Potwierd\u017A',
      resetFilter: 'Resetuj',
      clearFilter: 'Wszystko',
      sumText: 'Razem'
    },
    tree: {
      emptyText: 'Brak danych'
    },
    transfer: {
      noMatch: 'Brak dopasowa\u0144',
      noData: 'Brak danych',
      titles: ['Lista 1', 'Lista 2'],
      filterPlaceholder: 'Wpisz szukan\u0105 fraz\u0119',
      noCheckedFormat: 'razem: {total}',
      hasCheckedFormat: 'wybranych: {checked}/{total}'
    },
    image: {
      error: 'B\u0141\u0104D'
    },
    pageHeader: {
      title: 'Wstecz'
    },
    popconfirm: {
      confirmButtonText: 'Tak',
      cancelButtonText: 'Nie'
    }
  },
  AnswerWarrantyMoli: {
    warranty: 'objęty gwarancją',
    extended: 'Roszerzona gwarancja',
    expiredIn: 'Wygasła dnia',
    expired: 'nie objęty gwarancją'
  },
  AnswerProductInfo: {
    Learn: 'Dowiedz się więcej'
  },
  FromCallBack: {
    Call: 'Treść prośby o oddzwonienie',
    Time: 'Czat pozostały do końca sesji:',
    Sorry: 'Przepraszamy, proszę wypełnić puste pola',
    Name: 'Nazwać',
    NameErrorTips: 'Proszę zostaw swoje imię',
    Country: 'Kraj',
    Please: 'Please select the country you bought your product',
    Email: 'Email',
    EmailErrorTips: 'Please leave your email address',
    emailMessage: 'Jeśli miałeś wcześniej z nami kontakt, sugerujemy użycie tego samego adresu email.',
    PhoneNumber: 'Numer telefonu',
    PhoneNumberValidTips: 'Proszę zostawić poprawny numer telefonu',
    AvailableTime: 'Dostępny termin',
    AvailableTimeRequireTips: 'Proszę wybrać odpowiedni czas odpowiedzi.Pamiętaj, że otrzymasz połączenie z numeru "brak identyfikatora dzwoniącego"',
    Describe: 'Proszę opisać swój problem',
    Procudt: 'Produkt',
    IMEI: 'IMEI',
    submit: 'Składać',
    note: '¿Nos has contactado anteriormente?',
    require: 'jest wymagane'
  },
  Prompt: {
    thank: 'Dziękujemy za Twoją opinię.',
    surveyTimeText: 'Sesja wygasła. Nie możemy się doczekać aby ponownie udzielić Ci pomocy.',
    connecting: 'Nawiązywanie połączenia',
    Succeded: 'Rezerwacja powiodła się',
    Failed: 'Rezerwacja nie powiodła się',
    timeOut: 'Sesja została niestety zakończona z powodu braku aktywności. Jeżeli chcesz wrócić, przejdź do M.I.A. aby połączyć się ponownie. Przepraszamy za przerwę.',
    startOver: 'Zacznij od początku',
    close: 'Zamknij',
    Retry: 'Spróbuj ponownie',
    THANKS: 'Nie, dziękuję',
    ratevirtual: 'Oceń wirtualnego agenta'
  }
}

exports['default'] = pl
// # sourceMappingURL=pl.js.map
