'use strict'

Object.defineProperty(exports, '__esModule', { value: true })

const es = {
  name: 'es',
  btn: {
    ok: 'OK',
    confirm: 'Confirmar',
    yes: 'Sí',
    no: 'No',
    toagent: 'Chatear con el agente',
    exitAgent: 'Agente de salida',
    accept: 'Aceptar',
    noThinks: 'No, gracias',
    submit: 'Enviar',
    picture: 'picture',
    file: 'file',
    cancel: 'Cancelación',
    connecting: 'Conexión',
    login: 'Iniciar sesión'
  },
  temp: {
    dialog: {
      emptyMsgTips: '¡Por favor ingrese el contenido antes de enviar!',
      send: 'Enviar',
      sendTips: 'Presione la tecla enter para enviar'
    },
    header: {
      login: 'Iniciar sesión',
      logout: 'Desconectar',
      logoutLoadingText: 'Desconectar...',
      themeChange: 'Cambiar tema',
      bgChange: 'Cambiar el fondo'
    },
    slider: {
      clickPause: 'Haga clic para pausar la reproducción',
      clickPlay: 'Haz clic para reproducir automáticamente',
      clickLook: 'Clic para ver',
      oneClickSkinning: 'Desollar'
    },
    answer: {
      // AnswerCommonFaq.vue
      yes: 'Sí',
      no: 'No',
      confirmClear: '¿Estás seguro de que quieres borrar todos los favoritos?',
      confirmCancel: '¿Estás seguro de que quieres desfavorecerlo?',
      clear: 'vacío',
      cancel: 'desfavorecido',
      change: 'Siguiente página',
      // AnswerMultiIntention.vue
      more: 'Más',
      // AnswerMultiSteps.vue
      resolve: 'Problema resuelto',
      // AnswerRichtext.vue
      fullscreen: 'Pantalla completa',
      important: 'Important'
    },
    common: {
      relatedQuestion: 'preguntas relacionadas',
      fileSizeLimit: 'La imagen supera el límite',
      clickStore: 'Haga clic en favorito',
      clickCancelStore: 'Haga clic para desfavorecer',
      storeQuestion: 'pregunta favorita',
      prevStep: 'Paso anterior',
      nextStep: 'Próximo paso',
      note: 'Nota',
      buy: 'Comprar más',
      copy: 'COPY',
      copySuccess: 'Número de seguimiento copiado con éxito',
      from: 'From',
      off: 'OFF',
      parseDialog: 'Si enviar imágenes',
      parseDialogFile: 'Si enviar archivos',
      dialogTextarea: 'Puede editar el texto y enviar al mismo tiempo...'
    }
  },
  el: {
    colorpicker: {
      confirm: 'Confirmar',
      clear: 'Despejar'
    },
    datepicker: {
      now: 'Ahora',
      today: 'Hoy',
      cancel: 'Cancelar',
      clear: 'Despejar',
      confirm: 'Confirmar',
      selectDate: 'Seleccionar fecha',
      selectTime: 'Seleccionar hora',
      startDate: 'Fecha Incial',
      startTime: 'Hora Inicial',
      endDate: 'Fecha Final',
      endTime: 'Hora Final',
      prevYear: 'A\xF1o Anterior',
      nextYear: 'Pr\xF3ximo A\xF1o',
      prevMonth: 'Mes Anterior',
      nextMonth: 'Pr\xF3ximo Mes',
      year: '',
      month1: 'enero',
      month2: 'febrero',
      month3: 'marzo',
      month4: 'abril',
      month5: 'mayo',
      month6: 'junio',
      month7: 'julio',
      month8: 'agosto',
      month9: 'septiembre',
      month10: 'octubre',
      month11: 'noviembre',
      month12: 'diciembre',
      weeks: {
        sun: 'dom',
        mon: 'lun',
        tue: 'mar',
        wed: 'mi\xE9',
        thu: 'jue',
        fri: 'vie',
        sat: 's\xE1b'
      },
      months: {
        jan: 'ene',
        feb: 'feb',
        mar: 'mar',
        apr: 'abr',
        may: 'may',
        jun: 'jun',
        jul: 'jul',
        aug: 'ago',
        sep: 'sep',
        oct: 'oct',
        nov: 'nov',
        dec: 'dic'
      }
    },
    select: {
      loading: 'Cargando',
      noMatch: 'No hay datos que coincidan',
      noData: 'Sin datos',
      placeholder: 'Seleccionar'
    },
    cascader: {
      noMatch: 'No hay datos que coincidan',
      loading: 'Cargando',
      placeholder: 'Seleccionar',
      noData: 'Sin datos'
    },
    pagination: {
      goto: 'Ir a',
      pagesize: '/p\xE1gina',
      total: 'Total {total}',
      pageClassifier: '',
      deprecationWarning: ''
    },
    messagebox: {
      title: '',
      confirm: 'Aceptar',
      cancel: 'Cancelar',
      error: 'Entrada inv\xE1lida'
    },
    upload: {
      deleteTip: 'Pulse Eliminar para retirar',
      delete: 'Eliminar',
      preview: 'Vista Previa',
      continue: 'Continuar'
    },
    table: {
      emptyText: 'Sin Datos',
      confirmFilter: 'Confirmar',
      resetFilter: 'Reiniciar',
      clearFilter: 'Despejar',
      sumText: 'Suma'
    },
    tree: {
      emptyText: 'Sin Datos'
    },
    transfer: {
      noMatch: 'No hay datos que coincidan',
      noData: 'Sin datos',
      titles: ['Lista 1', 'Lista 2'],
      filterPlaceholder: 'Ingresar palabra clave',
      noCheckedFormat: '{total} art\xEDculos',
      hasCheckedFormat: '{checked}/{total} revisados'
    },
    image: {
      error: 'HA FALLADO'
    },
    pageHeader: {
      title: 'Volver'
    },
    popconfirm: {
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }
  },
  AnswerWarrantyMoli: {
    warranty: 'En garantía',
    extended: 'Garantía extendida',
    expiredIn: 'Caducado en',
    expired: 'Caducado'
  },
  AnswerProductInfo: {
    Learn: 'Learn more'
  },
  FromCallBack: {
    Call: 'Por favor complete su información',
    Time: 'Tiempo de espera:',
    Sorry: 'Por favor llene los campos vacíos',
    Name: 'Nombre',
    NameErrorTips: 'Per favore, lascia il tuo nome',
    Country: 'País',
    Please: 'Seleccione el país donde compró su producto',
    Email: 'Correo electrónico',
    EmailErrorTips: 'Deje su dirección de correo electrónico',
    emailMessage: 'Si nos ha contactado con anterioridad,  sugerimos proporcione ese mismo correo electrónico.',
    PhoneNumber: 'Número de teléfono',
    PhoneNumberValidTips: '11 dígitos requeridos',
    AvailableTime: 'Tempo disponibile',
    AvailableTimeRequireTips: 'Seleziona l\'orario di risposta appropriato. Tieni presente che riceverai una telefonata dal numero "nessun ID chiamante"',
    Describe: 'Por favor, describa sus problemas aquí.',
    Procudt: 'Modelo del producto',
    IMEI: 'IMEI',
    submit: 'Enviar',
    note: '¿Nos has contactado anteriormente?',
    require: 'se requiere'
  },
  Prompt: {
    thank: 'Gracias por tus comentarios.',
    surveyTimeText: 'La sesión ha expirado y esperamos poder servirle nuevamente.',
    connecting: 'Connecting',
    Succeded: 'Reserva completada',
    Failed: 'Reserva fallida',
    THANKS: 'NO GRACIAS',
    timeOut: 'Lo sentimos, su solicitud ha expirado. Si necesita reenviar, regrese a M.I.A para volver a aplicarla. Disculpa por la incoherencia.',
    startOver: 'Comenzar de nuevo',
    close: 'Cerrar',
    Retry: 'Reintentar',
    ratevirtual: 'Califica agente virtual'
  },
  message: {
    PromptPreSO: {
      title: 'Enviar para reparación',
      question1: 'Detalles del dispositivo',
      imeiTips: 'Confirma la información de tu dispositivo.',
      imeiInputTips: 'Ingrese el IMEI sin espacios. (Puede marcar <b style="color: #4e4e4e;">* # 06 #</b>en su teléfono para obtener este número de 15 dígitos o encontrar el IMEI en su ticket de compra o en la caja de su dispositivo.)',
      imeiErrorTips: 'Lo siento, ese formato no parece correcto.',
      imeiQueryRepairStatusTips: 'El dispositivo ya tiene una orden de reparación sin terminar.',
      warrantyTips: 'Confirma la información de tu dispositivo.',
      question1LoadingText: 'Confirmando la información de tu dispositivo. Esto puede llevar algún tiempo. Espera.',
      question2: 'Información de reparación',
      question2Tips: 'Proporcione su información de reparación.',
      firstName: 'Primer nombre',
      lastName: 'Apellido',
      contactNumber1: 'Número de contacto 1',
      contactNumber2: 'Número de contacto 2',
      email: 'Correo electrónico',
      postCode: 'Código postal',
      provinceOrState: 'Estado',
      city: 'Ciudad',
      address: 'Calle - número exterior - número interior',
      subAddress1: 'Colonia',
      subAddress2: 'Delegacion o municipio',
      references: 'Referencias',
      whatsAppNotification: '¿Quieres recibir notificaciones de cambios de estado de reparación a través de WhatsApp?',
      whatsAppNumberTips: 'Número de notificación de WhatsApp',
      rntNumber: 'Si se ha puesto en contacto con nuestro centro de atención telefónica, introduzca a continuación su número de referencia de 12 dígitos, por ejemplo, 000000-000000.',
      serviceNotes: 'Problema del dispositivo',
      question3: 'Opciones de reparación',
      question3Tips: 'Elija su opción de reparación.',
      carrMailTitle: 'Opciones de reparación.',
      carryIn: 'Visita el centro de servicio',
      mailIn: 'Envia por servicio de paquetería',
      questionAddressTitle: 'Dirección',
      question4: '4. Revisar la presentación',
      question4Tips: 'Revise la información antes de enviarla.',
      confirm: 'Confirmar',
      next: 'Siguiente',
      back: 'Paso anterior',
      findServiceCenter: 'Centro de servicio',
      edit: 'Editar',
      submit: 'Enviar',
      emptyTips: 'Esta opcion es requerida.',
      radioTips: 'Seleccione al menos uno'
    }
  }
}

exports['default'] = es
// # sourceMappingURL=es.js.map
