'use strict'

Object.defineProperty(exports, '__esModule', { value: true })

const ja = {
  name: 'ja',
  btn: {
    ok: '送信',
    confirm: '確認',
    yes: 'はい',
    no: '番号',
    toagent: 'エージェントとチャット',
    exitAgent: 'エージェントを終了',
    accept: '同意します',
    noThinks: '結構です',
    submit: '送信',
    picture: 'picture',
    file: 'file',
    cancel: 'キャンセル',
    connecting: 'せつぞく',
    login: 'login'
  },
  temp: {
    dialog: {
      emptyMsgTips: '送信前に内容を入力してください！',
      send: '送信',
      sendTips: '送信するにはエンターキーを押してください'
    },
    header: {
      login: 'ログインする',
      logout: 'ログアウト',
      logoutLoadingText: 'ログアウト...',
      themeChange: 'テーマを切り替える',
      bgChange: '背景を変更'
    },
    slider: {
      clickPause: 'クリックして再生を一時停止します',
      clickPlay: 'クリックして自動再生',
      clickLook: 'クリックしてご覧ください',
      oneClickSkinning: 'スキニング'
    },
    answer: {
      // AnswerCommonFaq.vue
      yes: 'はい',
      no: '番号',
      confirmClear: 'すべてのお気に入りをクリアしてもよろしいですか？',
      confirmCancel: 'お気に入りを削除してもよろしいですか？',
      clear: '空の',
      cancel: 'お気に入りではない',
      change: '次のページ',
      // AnswerMultiIntention.vue
      more: 'その他',
      // AnswerMultiSteps.vue
      resolve: '問題は解決された',
      // AnswerRichtext.vue
      fullscreen: '全画面表示',
      important: '重要'
    },
    common: {
      relatedQuestion: '関連する質問',
      fileSizeLimit: '画像が制限を超えています',
      clickStore: 'クリックしてお気に入りに',
      clickCancelStore: 'クリックしてお気に入りを削除',
      storeQuestion: 'お気に入りの質問',
      prevStep: '一つ前の手順',
      nextStep: '次のステップ',
      note: 'ノート',
      buy: '購入',
      copy: 'COPY',
      copySuccess: 'copy success',
      from: 'From',
      off: 'OFF',
      parseDialog: '画像を送信するかどうか',
      parseDialogFile: 'ファイルを送信するかどうか',
      dialogTextarea: 'テキスト送信を同時に編集することができます...'
    }
  },
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: '\u30AF\u30EA\u30A2'
    },
    datepicker: {
      now: '\u73FE\u5728',
      today: '\u4ECA\u65E5',
      cancel: '\u30AD\u30E3\u30F3\u30BB\u30EB',
      clear: '\u30AF\u30EA\u30A2',
      confirm: 'OK',
      selectDate: '\u65E5\u4ED8\u3092\u9078\u629E',
      selectTime: '\u6642\u9593\u3092\u9078\u629E',
      startDate: '\u958B\u59CB\u65E5',
      startTime: '\u958B\u59CB\u6642\u9593',
      endDate: '\u7D42\u4E86\u65E5',
      endTime: '\u7D42\u4E86\u6642\u9593',
      prevYear: '\u524D\u5E74',
      nextYear: '\u7FCC\u5E74',
      prevMonth: '\u524D\u6708',
      nextMonth: '\u7FCC\u6708',
      year: '\u5E74',
      month1: '1\u6708',
      month2: '2\u6708',
      month3: '3\u6708',
      month4: '4\u6708',
      month5: '5\u6708',
      month6: '6\u6708',
      month7: '7\u6708',
      month8: '8\u6708',
      month9: '9\u6708',
      month10: '10\u6708',
      month11: '11\u6708',
      month12: '12\u6708',
      weeks: {
        sun: '\u65E5',
        mon: '\u6708',
        tue: '\u706B',
        wed: '\u6C34',
        thu: '\u6728',
        fri: '\u91D1',
        sat: '\u571F'
      },
      months: {
        jan: '1\u6708',
        feb: '2\u6708',
        mar: '3\u6708',
        apr: '4\u6708',
        may: '5\u6708',
        jun: '6\u6708',
        jul: '7\u6708',
        aug: '8\u6708',
        sep: '9\u6708',
        oct: '10\u6708',
        nov: '11\u6708',
        dec: '12\u6708'
      }
    },
    select: {
      loading: '\u30ED\u30FC\u30C9\u4E2D',
      noMatch: '\u30C7\u30FC\u30BF\u306A\u3057',
      noData: '\u30C7\u30FC\u30BF\u306A\u3057',
      placeholder: '\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044'
    },
    cascader: {
      noMatch: '\u30C7\u30FC\u30BF\u306A\u3057',
      loading: '\u30ED\u30FC\u30C9\u4E2D',
      placeholder: '\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044',
      noData: '\u30C7\u30FC\u30BF\u306A\u3057'
    },
    pagination: {
      goto: '',
      pagesize: '\u4EF6/\u30DA\u30FC\u30B8',
      total: '\u7DCF\u8A08 {total} \u4EF6',
      pageClassifier: '\u30DA\u30FC\u30B8\u76EE\u3078',
      deprecationWarning: ''
    },
    messagebox: {
      title: '\u30E1\u30C3\u30BB\u30FC\u30B8',
      confirm: 'OK',
      cancel: '\u30AD\u30E3\u30F3\u30BB\u30EB',
      error: '\u6B63\u3057\u304F\u306A\u3044\u5165\u529B'
    },
    upload: {
      deleteTip: 'Del\u30AD\u30FC\u3092\u62BC\u3057\u3066\u524A\u9664\u3059\u308B',
      delete: '\u524A\u9664\u3059\u308B',
      preview: '\u30D7\u30EC\u30D3\u30E5\u30FC',
      continue: '\u7D9A\u884C\u3059\u308B'
    },
    table: {
      emptyText: '\u30C7\u30FC\u30BF\u306A\u3057',
      confirmFilter: '\u78BA\u8A8D',
      resetFilter: '\u521D\u671F\u5316',
      clearFilter: '\u3059\u3079\u3066',
      sumText: '\u5408\u8A08'
    },
    tree: {
      emptyText: '\u30C7\u30FC\u30BF\u306A\u3057'
    },
    transfer: {
      noMatch: '\u30C7\u30FC\u30BF\u306A\u3057',
      noData: '\u30C7\u30FC\u30BF\u306A\u3057',
      titles: ['\u30EA\u30B9\u30C8 1', '\u30EA\u30B9\u30C8 2'],
      filterPlaceholder: '\u30AD\u30FC\u30EF\u30FC\u30C9\u3092\u5165\u529B',
      noCheckedFormat: '\u7DCF\u8A08 {total} \u4EF6',
      hasCheckedFormat: '{checked}/{total} \u3092\u9078\u629E\u3057\u305F'
    },
    image: {
      error: '\u5931\u6557'
    },
    pageHeader: {
      title: '\u623B\u308B'
    },
    popconfirm: {
      confirmButtonText: '\u306F\u3044',
      cancelButtonText: '\u3044\u3044\u3048'
    }
  },
  AnswerWarrantyMoli: {
    warranty: '保証修理',
    extended: '保証対象外',
    expiredIn: 'から期限切れ',
    expired: '期限切れ'
  },
  AnswerProductInfo: {
    Learn: 'さらに確認します'
  },
  FromCallBack: {
    Call: '連絡を求める',
    Time: 'タイムアウトカウントダウン： 9:58',
    Sorry: '申し訳ありませんが、空のフィールドに入力してください。',
    Name: 'お名前',
    NameErrorTips: 'Per favore, lascia il tuo nome',
    Country: '国',
    Please: '製品を購入した国を選択してください',
    Email: 'メールアドレス',
    EmailErrorTips: 'Please leave your email address',
    emailMessage: '以前に弊社コールセンターへご連絡いただいていた場合は、その時のメールアドレスを記載することをお勧めします。',
    PhoneNumber: '電話番号',
    PhoneNumberValidTips: '11桁が必要です',
    AvailableTime: 'Available time',
    AvailableTimeRequireTips: 'Please select your appropriate answering time. Please note you will recieve a phone call from "no caller ID" number',
    Describe: '問題を説明してください',
    Procudt: 'モデル名',
    IMEI: 'IMEI',
    submit: '確定',
    note: '以前に連絡したことがありますか？',
    require: '必要とされている'
  },
  Prompt: {
    thank: 'ご回答いただきありがとうございました',
    surveyTimeText: 'アンケートへの記入はタイムアウトになります。またのご利用をお待ちしております。',
    connecting: '接続中...',
    Succeded: '予約に成功しました',
    Failed: '予約に失敗しました',
    timeOut: '申し訳ありませんが、アプリケーションがタイムアウトしました。 再提出が必要な場合は、M.I.Aに戻って再申請してください。 矛盾してすみません。',
    startOver: '最初からやり直す',
    close: 'クローズ',
    Retry: '再試行',
    THANKS: '同意しない',
    ratevirtual: 'Lenaとのチャットについて'
  }
}

exports['default'] = ja
// # sourceMappingURL=ja.js.map
